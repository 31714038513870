import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesMaidenIce: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Maiden: Ice Rose guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_xmaiden.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Maiden: Ice Rose Guide & Review</h1>
          <h2>
            A guide & review for Maiden: Ice Rose in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>07/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Maiden: Ice Rose" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="maiden-ice-rose"
                  enablePopover
                />
              </div>
              <p>
                An icy heart and personality on the outside, but just as loving
                and caring from the inside. Maiden: Ice Rose joins the
                Commander’s party as they set out on an adventure in a world
                filled with Guillotine’s ideals. Compared to the Normal Maiden,
                it seems that xMaiden has been given buffs not only in the
                skillset aspect, but also physically (cough, what a ginormous
                cake, cough). Sorry for that cough, winter has arrived and
                people have been getting sick, so stay warm, cozy and healthy! A
                Defender only by name, let us dive in together and check out
                what this Icy Mage has in store for us!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Maiden is a support and DPS character whose skills revolve
                around obtaining and spending MP. She can either buff the team
                by replenishing MP or consume all of them to buff herself and
                deal devastating damage. When consuming MP stack(s), she
                strengthens herself by increasing her ATK relative to her final
                Max HP and inflicts a monstrous multi-hit attack to the target
                closest to the crosshair. She then empowers her normal attacks
                for a while, during which a large portion of her HP is also
                translated into ATK.
              </p>
              <p>
                XMaiden will see a lot of use in PvE bossing scenarios (esp.
                Mirror Container AI), thanks to her impactful Elemental DMG
                buffs that she bestows to her allies (more apparent if they have
                less Elemental DMG OL and skills due to dilution, ironically).
                Not only that, she can also act as a secondary DPS or a primary
                DPS depending on your team comp. With Cinderella being the best
                Electric damage dealer, it is more likely that you will see
                XMaiden as a support outside Raid content. However, even as a
                support, she can still deal a lot of damage thanks to her MP
                accumulation maneuvers!
              </p>
              <p>
                Her mobbing performance is a bit disappointing as she suffers
                from the incapability of dealing notable AoE damage. Her skills
                can only target one enemy at a time, and her base normal attack
                damage pales in comparison to her S2 damage. She also requires
                stacking Max HP to reach full potential, which may take between
                75-100s (unless you use Flora). This makes her not short-battle
                friendly. Despite her drawbacks, she can still be used as an
                alternative B3 in your Electric comp, as an Elemental DMG buffer
                for your other Electric DPS, or straight up as a provisional DPS
                and burst gen support if there really is nothing better (like in
                Elysion Tower).
              </p>
              <p>
                In PvP, she is niche as a Main DPS but still a very capable
                generalist burst generation filler unit, since her burst gen is
                quite high, even if not near the level of Clip RLs. She is
                designed for extended combats, not short-lived ones. Without
                personal Max HP buffs, she will have to rely on her teammates to
                strengthen her attacks, but that itself is not enough. Her lack
                of AoE damage means she is countered the same way as Alice and
                RH are, and that is a glaring problem. Nevertheless, she is
                still a good battery and offers a generous amount of burst gen.
                She inflicts decent single-target pressure enough to be
                classified as a good PvP unit, whether in-burst or off-burst.
                Her supportive skill may be useful in some scenarios.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Definitely. While XMaiden may not be the top-notch Electric
                damage dealer (that title belongs to Cinderella), she is still
                an incredible support and an extraordinary secondary DPS. She
                possesses a special kit that lets her store all of her damage
                and unleash all of it at the final second to absolutely butcher
                the enemy. Her Elemental DMG supportive skill makes her one of
                the best sidekicks for Cinderella, or literally any Electric DPS
                that exists. Don't have those DPS? Don't worry, you can still
                use her as a primary DPS! She is very versatile when it comes to
                roles. Essential for Raid and strong in PvP, and solid for
                Elysion Tower.
              </p>
              <p>
                Copies are not mandatory but welcome if you already have a built
                (and limit-broken) Cinderella or SAnis because you will need a
                good support to match their breathtaking performance. However,
                it is less important than dupes are for them.
              </p>
              <p>
                If you only have a limited number of resources and don't have
                both XMaiden and XLud, and you are just starting out, XLud would
                probably be the better option for you. She is just more present
                in the overall meta and thrives against any element (an
                excellent B3 support that’s not uber-broken but works anywhere).
                She is also really good in campaign because MG has perfect
                accuracy.
              </p>
              <p>
                Comparing that to XMaiden whose main purpose is to push the
                potential of the best Electric comp further, you are going to
                find it hard to gain value out of her without having Cinderella,
                Ein, and/or SAnis. Now, this recommendation may change if you
                have a perfectly built Cinderella, Ein, or SAnis, as then
                XMaiden’s value will go up significantly. In that case, we leave
                the decision to you. In a perfect world, you don’t want to miss
                both.
              </p>
              <p>
                In the end, we would like to remind you that no matter what, you
                should keep 200 Golden Mileage Tickets (or an equivalent of that
                in pulls) for the upcoming New Year (usually Pilgrim) Nikke, who
                is probably going to be super overpowered. Who knows RH might
                rerun as well?
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/xmaiden_guide_1.webp"
                alt="Kit"
              />
              <p>
                XMaiden wields a Rocket Launcher that stores 6 ammo in total at
                base form and has a reload time of 2.0s. It has a regular
                range/radius but generates more burst gen than average (at 1.82
                per hit). Thanks to her S2, she also triggers one proc of
                additional damage upon full-charging any valid non-terrain
                target, increasing her burst gen by an additional 1.82 per shot.
                However, since most of her basic attack damage comes from S2,
                XMaiden has weak collateral damage.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/xmaiden_guide_2.webp"
                alt="Kit"
              />
              <p>
                She also gets a butt upgrade cough, I mean design upgrade. That
                damn cough again..
              </p>
              <h5>Recover or Replenish</h5>
              <blockquote>
                <p>
                  ■ Activates when entering Burst Stage 1. Affects self when MP
                  is 0.
                </p>
                <p>
                  MP recovers by 1. MP can be accumulated up to a maximum of 12.
                  All accumulated MP is consumed when using Burst Skill.
                </p>
                <p>
                  ■ Activates when entering Full Burst. Affects self when MP is
                  above 1.
                </p>
                <p>
                  MP replenishes by 1. MP can be accumulated up to a maximum of
                  12. All accumulated MP is consumed when using Burst Skill.
                </p>
                <p>
                  ■ Activates when attacking with Full Charge for 6 time(s).
                  Affects self.
                </p>
                <p>
                  Max HP ▲ 6.34% without restoring HP for 15 sec, stacks up to
                  10 time(s).
                </p>
              </blockquote>
              <p>
                XMaiden regulates a special resource called MP, which has a
                visual effect in battle. MP can stack up to 12 and re-charges in
                two ways: if it is 0, then she gains +1 upon entering Burst
                Stage I, and if she has more than 1 upon entering Full Burst,
                then she recovers +1 more. In other words:
              </p>
              <ul>
                <li>
                  If she has 0 MP upon starting a burst cycle, she will end up
                  having 2 MP upon entering Full Burst. This is because both the
                  first and second line of the skill are triggered.
                </li>
                <li>
                  If she has 1 MP upon initiating a cycle, she will still only
                  have 2 MP upon entering Full Burst because the first one does
                  not trigger.
                </li>
                <li>
                  Similarly, if she uses Burst Skill in a rotation (which
                  consumes all her MP to 0 immediately), then she will not
                  replenish any MP upon entering Full Burst in that rotation.
                  This is because the second part of the skill does not trigger
                  (Burst Skill takes precedence over S1 in activation order).
                </li>
              </ul>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> No, It
                  Cannot Be Refreshed
                </h6>
                <p>
                  MP cannot be refreshed by external means, such as by using
                  stack refresh, and can only be replenished by natural means.
                </p>
              </blockquote>
              <p>
                Every time Maiden performs a full charge for 6 time(s), she also
                gains a stack of Max HP that stacks up to 10 times and lasts for
                15s. As long as she keeps shooting, this can be considered
                permanent. In base form, without Max Ammo & Charge Speed OL, it
                will take around 75-100s to reach max stacks. Stack refreshers
                can help charge this faster, most notably Flora (+1 stack every
                1.67s), who can lower the time requirement to just ~14s with
                enough Max Ammo and/or No Reload. The Max HP plays an important
                role in determining the output of her Burst Skill and also
                affects her basic attacks' damage thereafter. Yes, 63.4%▲ MAX HP
                (at maximum stacks at maximum level) is a big number.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Is It
                  Useful? Do I Really Need It?
                </h6>
                <p>
                  Max HP▲ does not affect the strength of her supportive skills
                  and has no immediate impact if she never uses Burst Skill,
                  which would be the case if you are using her as a full
                  support. Invest smartly.
                </p>
              </blockquote>
              <h5>Greed or Modesty</h5>
              <blockquote>
                <p>
                  ■ Activates when MP is replenished. Affects all Electric Code
                  allies except for self.
                </p>
                <p>Damage as strong element▲ 40.9% for 10 sec.</p>
                <p>ATK ▲ 20.9% of caster's ATK for 10 sec.</p>
                <p>■ Activates when MP is used. Affects self.</p>
                <p>Damage as strong element ▲ 31.68% for 10 sec.</p>
                <p>ATK ▲ 3.2% of caster's final Max HP for 10 sec.</p>
                <p>
                  ■ Activates when attacking with Full Charge for 1 time(s).
                  Affects1 enemy unit(s) nearest to the crosshair.
                </p>
                <p>Deals 547.62% of final ATK as damage.</p>
              </blockquote>
              <p>
                Maiden can buff the team or buff herself depending on whether
                she uses Burst Skill in that rotation.
              </p>
              <ul>
                <li>
                  If she doesn't use Burst Skill, she will enhance other
                  Electric units' Elemental DMG and ATK proportional to her own
                  ATK. The direct impact of this skill will depend on the
                  investment of your other electric DPS:
                </li>
                <ul>
                  <li>
                    Technically, Elemental DMG can be diluted. Therefore, the
                    Elemental DMG can be more felt by units that don't have
                    Elemental DMG skills & OL.
                  </li>
                  <li>
                    However, if your other Electric units are more invested,
                    then you will see more team damage gain from this support
                    skill. So, don't forsake them!
                  </li>
                  <li>
                    By following the same thinking process, you will gain more
                    mileage if you pair XMaiden with stronger Electric units
                    like Cinderella.
                  </li>
                  <li>
                    Even then, this skill is nearly useless if the enemy is not
                    Electric-weak and is totally useless if there are no
                    Electric allies to buff.
                  </li>
                </ul>
                <li>
                  If she uses Burst Skill, she will not buff her teammates but
                  instead boost her own Elemental DMG and ATK relative to her
                  Max HP. Note that she does not have enhanced basic attacks
                  outside her Burst Rotation(!), for ~16s out of each 26s (7s
                  CDR). This greatly impacts her PvE performance at high stat
                  deficit.
                </li>
              </ul>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Caster's ATK
                </h6>
                <p>
                  The supportive ATK buff is a caster's ATK, and since it is
                  derived from defender stat, it is weaker than the number
                  suggests.
                </p>
              </blockquote>
              <p>
                Additionally, every time XMaiden performs a full charge on a
                valid target, she deals additional damage equal to 547.62% (at
                max level). This is very significant as the ratio is high,
                meaning that most of her basic attack damage is actually from
                this and not her normal attack multiplier. It can crit but
                cannot core-hit. And, as usual, RLs don't have damage distance
                bonus.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> More Burst
                  Gen Never Hurts
                </h6>
                <p>
                  This extra additional damage is valuable in PvP as it gives
                  her 25% more burst gen per shot. It also doubles her natural
                  regeneration in PvE (no spam shot) against single targets.
                  Epic!
                </p>
              </blockquote>
              <h5>Now or Last</h5>
              <blockquote>
                <p>■ Affects 1 enemy unit(s) nearest to the crosshair.</p>
                <p>
                  Deals damage equal to 1372.8% of ATK that is calculated based
                  on 10% of the caster's final Max HP. Attacks continuously
                  based on the current MP.
                </p>
              </blockquote>
              <p>
                Based on the amount of MP she possesses, XMaiden will launch
                continuous attacks on a target. One MP means one attack, and
                since 12 MP is the max that you have, you can "delay" and store
                up to 13728% instantaneous damage. This is unaffected by the
                1.5× Full Burst Multiplier, but it uses a different ATK stat set
                to XMaiden's ATK + 10% of her final Max HP. Given high stats,
                this can potentially exceed the destructiveness of 2B's katana
                or even Cindy's slippers!
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Please Pay
                  the EN Interns
                </h6>
                <p>
                  The EN translation of this skill does not match the actual
                  skill effect. The English version states that only 10% of the
                  caster's final Max HP is accounted for in the Burst's ATK stat
                  calculation (like Kilo's), but this is wrong. The ATK stat is
                  actually still included but padded by the Max HP → ATK
                  conversion. This was only recently fixed in the skill
                  descriptions.
                </p>
              </blockquote>
              <p>
                Since she does not lose out on much damage by not using Burst
                Skill (her MP stores that unused damage), fans entertained the
                idea of stationing her an off-burst unit and only using her
                Burst Skill at the end, at which point her MP would have reached
                max stacks, funneling all her Burst Damage into that one blast.
                This comes with abundant benefits; we'll discuss them in the
                next section.
              </p>
              <div className="section-scroll" id="section-2">
                <SectionHeader title="Gameplay" />
                <p>
                  XMaiden can be played as a main DPS, an off-burst DPS/support,
                  or as a full support.
                </p>
                <ul>
                  <li>
                    As a DPS, XMaiden will use her Burst Skill every other
                    rotation, sacrificing her team buffs for personal damage.
                    This is good when buffing the team is less efficient than
                    buffing herself, such as when the ELE buffs are useless, or
                    if Maiden herself is extremely well built (investment and
                    OL-wise) and is able to out-DPS the DPS that was supposed to
                    replace her.
                  </li>
                  <li>
                    As an off-burst DPS/support, XMaiden acts as a support and
                    secondary DPS. She will not use her Burst Skill every other
                    rotation. In this setup, XMaiden will only use her Burst
                    Skill at the very end of a battle, treating her as a support
                    the whole run and making use of her MP charging capability.
                    Some comps even feature her Bursting after a certain number
                    of rotations (usually 5), at which point her S1 Max HP▲
                    would have fully stacked up and Rouge’s S2/Burst Skill too.
                  </li>
                  <li>
                    As a full support, XMaiden will not use her Burst Skill at
                    all. This is the best option for non-built XMaiden as she
                    will not have any damage at all even when she unleashes her
                    Burst Skill with 12 MP. It is better to buff your better
                    built Electric teammates.
                  </li>
                </ul>
                <p>
                  So, which one is better? Well, the only question that matters
                  is whether her being one of the alternating B3 burst-ers is
                  better than her being in the off-burst. In some situations,
                  you need to run 2-1-2 or 1-2-2, and that is understandable.
                  XMaiden needs to burst there, which automatically makes her a
                  DPS. But, if we are talking about maximizing the damage
                  potential of a single team (which is usually the case outside
                  Raid but only sometimes in Raid), XMaiden shines best in a
                  1-1-3 setup.
                </p>
                <p>
                  Why 1-1-3? Well, that is because she is designed to be an
                  off-burst support, buffing all her Electric allies as she
                  collects MP and unleashing all her MP as damage toward the end
                  of a 180s fight. This way, she can keep buffing her Electric
                  teammates for the entire duration of the fight and not just
                  every other Full Burst. She also does not steal the other B3’s
                  Burst Skill, which may be vital for dealing damage. Moreover,
                  her Max HP takes ages to stack, and it's more worth using Max
                  HP-related skills after you have it stacked high enough. With
                  fewer stacks comes weaker Burst Skill and enhanced S2 basic
                  attacks.
                </p>
                <p>
                  <i>
                    But Sir, this means we won't trigger her enhanced basic
                    attacks. Won't that mean XMaiden will lose a lot of personal
                    damage?
                  </i>
                </p>
                <p>
                  Yes, she absolutely will (est. 25-45% depending on
                  investments, see -{' '}
                  <a
                    href="https://x.com/Reign_NIKKE/status/1864644379002196105"
                    target="_blank"
                    rel="noreferrer"
                  >
                    this post
                  </a>
                  ), but it is more likely that your team will compensate for
                  it. You see, her Elemental DMG and ATK buff for the team
                  (except herself) are pretty broken. As long as they have a use
                  and result in more damage output, then delaying Burst Skill
                  for as long as possible will be more beneficial. Oh, but what
                  if it results in less damage? Well, then use XMaiden's Burst
                  Skill instead. However, whether that's better is for you to
                  test. We can't guess how your team will do without you testing
                  it. Investment and OL differences matter.
                </p>
                <p>
                  Again, this is not a requirement. You can run her as a DPS in
                  a 1-1-3 comp if her support skills are useless. For example,
                  in Elysion Tower, if you run her with PrivM and Helm against a
                  swarm of Iron Mobs, then it is somewhat pointless to store MP.
                  You will gain more mileage by using her Burst Skill and
                  buffing her basic attacks to deal with the mobs. If you are
                  also slotting her in a team against a non-Electric-weak boss,
                  then the big part of her supportive skill (aka the Elemental
                  DMG) is also gone.
                </p>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                Her DPS performance in high-stat-deficit mobbing is very
                limited, like Cindy, but at least Cindy has ATK buff on basic
                attack every rotation. Stat deficit is brutal for defenders. Her
                supportive off-burst performance should be fine, though, not
                broken but great, but it's limited to Electric comp against
                Water mobs. ATK is also more preferred in higher deficits, so
                SAnis and Maxwell can outperform XMaiden in most cases and when
                versus different elements (if buffing Supports/Attackers).
              </p>
              <p>
                In low-stat-deficit mobbing, XMaiden will likely be able to
                manage some stages and mobs, and serve as a good burst
                generator. However, her lack of AoE damage (her S2 only targets
                one enemy, and her Burst Skill too) might be a shame. She cannot
                even stack Max HP or MP long enough to land a "devastating 12×
                combo on the stage boss for instant kill". Work around her
                weaknesses and perhaps you can clear more stages with her! We
                don't have many good Elysion units, so we are just gonna say
                here that she's probably good for Elysion Tower.
              </p>
              <p>
                We don't have many good Elysion units, so we are just gonna say
                here that she's most likely good for Elysion Tower.
              </p>
              <p>
                Mainly, we recommend using her as a unit to support Electric DPS
                only, and just do not burst with her if you plan to use her.
                Think of her as a Maxwell or Privaty of sorts, who only exists
                to buff Electric allies on Electric-weak stages.
              </p>
              <h5>
                Bossing - <strong className="ss">SS</strong>
              </h5>
              <p>
                BiS in the "Best Electric Bossing Comp Against Water Without CP
                Penalty" — Cinderella Rouge SAnis XMaiden Crown, whose main
                gameplay idea was highlighted in the Gameplay section. For
                Mirror Container AI, this might as well just be the best team
                that everyone would find comfortable clearing S9 with.
              </p>
              <p>
                Against non-Electric-weak bosses, her supportive skills are
                weakened. Why so? That is because her supportive ATK buff is
                small (it scales off defender's stat), and ELE buff is useless.
                Because of that, she will likely be a DPS, where her Burst Skill
                will be used in every other rotation, but if you want, she still
                can be an off-burst support/DPS, just weaker.
              </p>
              <p>
                Note that, when there is CP penalty and high stat deficit,
                XMaiden may suffer from "too little ATK" as a defender as she
                does not have ATK buff outside her Burst Rotation. Despite that,
                her supportive skill is still intact and profitable. A special
                exception would be against Mirror Container, which has a lot of
                DEF. Cindy + Ein + SAnis is likely still better there
                (hypothetical) because true damage is more effective in higher
                deficits.
              </p>
              <h5>
                PVP - <strong className="s">S</strong>
              </h5>
              <p>
                xMaiden is a pretty decent Nikke to use in PvP for one very
                strong reason: Her Burst Generation. Her burst gen (sitting at
                9.1 per RL) is ever so slightly higher than Clip Shotguns, who
                are notorious for feeding into Scarlet and Jackal. However,
                xMaiden, wielding a Rocket Launcher, is not prone to such
                disadvantages. Hence, not only does she have higher burst gen
                than Clip SGs, but she also does not feed.
              </p>
              <p>
                This makes her a solid choice for being a Burst Gen filler,
                where before we would have had to use an SG to fill the burst
                gen requirement. A unit like this is highly appreciated in the
                meta, especially due to her extra tankiness allowing her to
                survive more than other units would. That said, on defense, she
                will not inflict backline pressure like SGs do, which may or may
                not be a good thing.
              </p>
              <p>
                The fun does not stop here, however. xMaiden also hits
                super-duper hard with her normal shots, be it in burst, or
                outside of burst. Each fully charged shot, summing the base + S2
                multiplier, deals ~700% damage. Being a Defender, she hits for
                0.66× that of an Attacker unit, which means that she hits for
                almost double the value of what an average Attacker unit hits
                for normally outside of burst!
              </p>
              <p>
                This just gets even better when she is in burst and she herself
                has used burst, since now she is able to get the HP → ATK buff
                from Skill 2, which allows her to hit immensely hard! While this
                is still a niche usage burst since it is a single target nuke
                followed by focus target damage that can only damage 1 unit
                seriously, this is still something to keep in the back of one’s
                mind. A team with no proper sustain can fall prey to xMaiden’s
                basic damage and lose. Overall, this is just a small addition to
                an already proven fact: xMaiden is a very viable unit for PvP,
                and will be an important unit for Champions Arena.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                XMaiden scales off her Max HP & ATK as an in-burst DPS, and
                partially scales off her Max HP & ATK as an off-burst
                support/DPS, and scales off her ATK only as a pure off-burst
                support. However, her supportive skill gear scaling is weak, so
                she can just work without OL; only her damage will be affected.
              </p>
              <p>
                As an in-burst DPS, she hurriedly needs 1× Max Ammo to ensure
                she has enough ammo to keep shooting in the 10s Full Burst
                window without reloading at all (a single shot missed is a big
                damage loss). Charge Speed also allows her to reach max stacks
                of Max HP faster, the core of her damage buffs. There are also
                Charge Speed breakpoints that you can achieve — 2× Charge Speed
                will allow XMaiden to shoot 8 times guaranteed every 10s Full
                Burst instead of a hit-or-miss 7/8.
              </p>
              <p>
                As an off-burst DPS or a support, she will still find good use
                out of 1× Max Ammo, but prioritizing ELE/ATK might be smarter
                for that final avalanche (12MP Burst Skill). Having 1× Max Ammo
                is good for flexibility, though.
              </p>
              <p>
                Remember, don't over-OL/reroll a semi-DPS. OL meta ones instead.
                Save rocks as New Year is close.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> 0-1× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK, 0-1× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 2-3× Charge Speed
                </li>
                <li>
                  <strong>Priority:</strong> Medium
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                There are two skill routes for XMaiden. One where you care about
                her DPS, and one where you don't. If you care about her DPS,
                invest in S1 and Burst Skill alongside S2. If you only care
                about supporting (and are willing to forsake her damage as a
                secondary DPS), focus on S2.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 7~10</strong>
                </li>
                <ul>
                  <li>
                    More Max HP▲ means more personal damage as a DPS and more
                    explosion damage as an off-burst secondary DPS + support.
                    Diluted by other Max HP▲ buffs.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 10</strong>
                </li>
                <ul>
                  <li>
                    Where her insanely broken supportive skills lie, and a
                    significant portion of her personal damage buffs and basic
                    attack (skill damage) ratio. Prioritize.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    Upgrade if you want more damage out of her icicles. More
                    important for off-burst support/DPS route than DPS route.
                    Remember that it is affected by ATK + MAX HP, so upgrade
                    other skills too and her/teammates' gear/OL!
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="maiden-ice-rose" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <ul>
                <li>
                  Outside Raid, she is more likely to be deployed in the "Best
                  Electric Bossing Comp Against Water Without CP Penalty" comp,
                  which features Reload Speed buffers like Crown and SAnis. In
                  this case, like usual, Resilience is always the best option
                  because of Reload Speed stacking. In Raid, this may change
                  depending on whether the boss is Electric-weak.
                </li>
                <li>
                  That being said, without Reload Speed buffers, both Resilience
                  and Bastion are similar in performance. Which one you like the
                  most, or which one has the highest level, pick that. Just make
                  sure she can shoot for the whole 10s of Full Burst when she
                  uses Burst Skill.
                </li>
              </ul>
              <h5>Doll Investments</h5>
              <p>
                Good presence in PvE and PvP content and thus benefit from CP
                padding. Dolls give fixed HP, DEF, and ATK value, so they are
                more effective on Defenders who scale off Max HP.
                Weapon-specific buffs are pretty good. Her main team outside
                Raid will feature healerless comps, so Damage Taken▼ and Cover
                HP▲ are highly appreciated.
              </p>
              <p>Verdict: SR 0 or SR 5</p>

              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The Best Electric Bossing Comp Aga- </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The Best Electric Bossing Comp Against Water Without CP Penalty.
                Yes, that's right! Basically, in this comp, Rouge buffs Cindy's
                and XMaiden's Max HP. Cindy and SAnis alternate Burst until the
                Burst before the very end, where Rouge + Crown + XMaiden S1 + 12
                MP unleash obliteration on the enemy. XMaiden will also buff
                Cindy's, SAnis's, and Rouge's ELE DMG and ATK nearly the whole
                run. Read the <strong>Gameplay Section</strong> above for
                detailed info.
              </p>
              <h5>Team #2: Ein and Exia took away Crown</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="cinderella"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                In Raids, this team might pop up on electric weak bosses. The
                idea is to split the 4 mega DPS of electric into 2 teams. One
                team, with Crown, gets Exia, S.Anis and Ein. Exia Crown Ein
                S.Anis Dolla/DWife. The rest.. is this team. This team allows
                for funneling damage into Cinderella and xMaiden. With
                xLudmilla’s Damage Taken debuff, we are offered a new type of
                buff multiplier, which helps Cinderella and xMaiden deal even
                more damage!
              </p>
              <h5>Team #3: Elysion is Saved, Maybe</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mast" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="diesel"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
              </div>
              <p>
                Finally, a good battery for Elysion Tower! XMaiden has good
                burst gen, so you don't have to rely on spamming DWife or Vesti
                anymore. DieselTr can ensure that the team never reloads
                (including XMaiden) and is protected via taunt, so if you use
                Guillotine, that's good. Mast is B2, and since XMaiden gains ATK
                buff only after Full Burst (in her rotation), you might need to
                invest in XMaiden just enough that her base ATK edges out at
                least 2 other non-Mast Nikke(s). Quiry might help, but might be
                a waste of a slot.
              </p>
              <h5>Team #4: STACK FASTERRRRR</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="flora" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                    <NikkeCharacter mode="icon" slug="mast" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Flora's and Crown's positions can be adjusted, but Crown's Burst
                Skill must be used (manual may be needed). Note that it might be
                smart to position Flora near XMaiden or the other B3 because
                they are more likely to be targeted if using Burst Skill that
                increases ATK.
              </p>
              <p>
                In this comp, we focus on stacking XMaiden's MAX HP stacks as
                fast as possible, so she can be a lethal DPS. Note that in this
                comp, she is less of a support and more of a DPS, although she
                does have some supporting potential in her off-burst, buffing
                the other B3 (if Electric) and Flora.
              </p>
              <p>
                If you use Ein (Flora can also buff Ein), you can go something
                like Rouge &gt; Flora &gt; Ein, then Rouge &gt; Mast &gt;
                XMaiden, but make sure XMaiden has more base ATK than Flora and
                Rouge (so Mast's Burst targets her).
              </p>
              <p>
                If you prefer something else, SAnis can allow the maximization
                of uninvested Flora because she can reach the point of No
                Reload, so Max Ammo OL is no longer an urgent requirement.
                Otherwise, Flora without Max Ammo OL will be slower in building
                stacks and offer less DPS.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Simply Buffing with Burst Gen</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="moran" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                3RL - xMaiden in this team is simply used as a Burst Generation
                unit. But, if this team survives an AoE burst, it can allow for
                some serious damage output, where Anis targets the highest ATK
                unit and pressures the backline, and xMaiden pressures the
                frontline. Anis constantly heals up as well due to Biscuit
                looking out for her.
              </p>
              <h5>Block Them out of Your Life</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                3RL - can be 2RL if Lvl 7 quantum cube is given to xMaiden,
                Centi and Anis. A fast single-target nuke, where Noise is there
                to buff xMaiden’s Normal Attacks even more. Biscuit ensures
                xMaiden’s survival, while she tries to finish off the enemy team
                using her normal attacks. Any team without sustain will have a
                hard time facing off against this team.
              </p>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          One of the best Electric buffers in the market and the
                          only one that grants ELE DMG▲.
                        </li>
                        <li>Decent PvE and PvP presence.</li>
                        <li>Can be a DPS or a support.</li>
                        <li>
                          Huge semi-DPS potential even as an off-burst support.
                          She does not steal other units’ B3.
                        </li>
                        <li>
                          Big Burst Damage if max MP and S2 Basic Attack Ratio.
                        </li>
                        <li>Good burst gen in PvP.</li>
                        <li>Might save Elysion.</li>
                        <li>
                          She went to Korea to get plastic surgery and went back
                          with buffed boobs and butt.
                        </li>
                        <li>Competes with 2B for best butt in Nikke.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Cannot activate team buffs and self buff at the same
                          time.
                        </li>
                        <li>MP is not refreshable by stack refreshers.</li>
                        <li>
                          Max HP▲ and MP stacking takes too long for shorter
                          combats.
                        </li>
                        <li>DPS weakens outside Electric-weak scenarios.</li>
                        <li>
                          Supportive skill significantly weakens outside
                          Electric-weak scenarios.
                        </li>
                        <li>DPS is high-deficit sensitive.</li>
                        <li>Seasonal/Limited unit.</li>
                        <li>
                          ShiftUp literally forgot to post her kit; posted
                          xGuillotine’s kit first, followed by 2 maintenance
                          reminders, and only then posted her kit after
                          maintenance had started.
                        </li>
                        <li>
                          And yet, they still revised her kit picture 2 times.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay</a>
                </li>
                <li>
                  <a href="#section-3">Tier List</a>
                </li>
                <li>
                  <a href="#section-4">Investments</a>
                </li>
                <li>
                  <a href="#section-5">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-7">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMaidenIce;

export const Head: React.FC = () => (
  <Seo
    title="Maiden: Ice Rose Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Maiden: Ice Rose in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
